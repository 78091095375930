<template>
  <div class="share-page">
    <div v-if="!untie" class="pass">
      <div>
        <div class="content">
          <avue-input
            v-model="passInput"
            class="pass-input"
            placeholder="请输入提取码"
          />
          <el-button type="primary" @click="confirm">确认</el-button>
        </div>
        <p v-show="isError">请输入正确的提取码</p>
      </div>
    </div>
    <div v-else class="url">
      <iframe :src="iframeSrc" frameborder="0" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Share',
  data() {
    return {
      passInput: '',
      untie: false,
      iframeSrc: '',
      isError: false
    }
  },
  computed: {
    headers() {
      return {
        Authorization: 'Bearer ' + this.token
      }
    }
  },
  watch: {
    '$route.params': {
      async handler(newVal) {
        // console.log(newVal)
        this.token =
          newVal.t ||
          'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImE1ZjJmODUxLTUyZWMtNGZhNi1iYjM5LWFjY2FlNTI3NmU4YiJ9.PM-3A2b0o3x-QuxwuNZ6FbIEa3ncbgXIslPUvtkoZ6UUFVKh_1Y0fjIVbe2gPgsx0MF09ZKZsgWpHDd1MMVABA'
      },
      immediate: true
    },
    passInput(val) {
      if (val == '') {
        this.isError = false
      }
    }
  },
  methods: {
    confirm() {
      fetch(`${this.baseUrl}/archives/share/pass/${this.passInput}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        mode: 'cors'
      })
        .then((e) => e.json())
        .then((res) => {
          console.log(res)
          if (res.code == 200) {
            this.untie = true
            this.iframeSrc = res.data.url
          }
          this.isError = true
        })
        .catch((e) => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.share-page {
  height: 100vh;
  .pass {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      display: flex;
      align-items: center;
      .pass-input {
        margin-right: 10px;
      }
    }
    p{
      margin: 8px 0;
      color: #ff0000;
    }
  }
  .url {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
